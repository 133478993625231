<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="openModal"
      width="width:38rem"
      padding="padding:0 1.2rem"
      @submit="handleSubmit(submit)"
      :disabled-button="disableButton"
      submit="Apply"
      @close="closeModal"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <p class="text-darkPurple capitalize">New Payrun</p>
      </template>

      <div class="mt-5 px-1 w-full flex">
        <p class="text-xs text-flame font-black">Filter By</p>
      </div>

        <div class="flex p-1 mt-3">
          <SearchInput
            placeholder="First, Last Name or Email"
            v-model="handleSearch"
            @input="searchFilter($event)"
            class="search"
            style="width: 100%;"
          />
        </div>

          <div class="flex w-full flex-col p-1 mt-4">
              <label class="date-label">Location(s)</label>
              <v-select
                :clearable="false"
                class="style-select"
                multiple
                :disabled="disableSelect"
                :reduce="location => location.id"
                :options="locationData"
                :close-on-select="false"
                v-model="payload.locationId"
                @input="selectedLocations"
              >
                <template #option="{ name, id }">
                  <div class="flex">
                    <div class="hover:text-white">
                      <div class="flex justify-start items-center gap-2">
                        <c-checkbox :true-value="!payload.locationId.includes(id)" @focus.prevent :key="id"  />
                        <span class="font-bold text-base cursor-pointer">{{ name }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <template #selected-option="{name}">
                  {{ name }}
                </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <Icon icon-name="caret-down" size="xsm" />
                  </span>
                </template>
              </v-select>
          </div>


          <div class="flex w-full flex-col p-1 mt-4">
              <label class="date-label">Function(s)</label>
              <v-select
                :clearable="false"
                class="style-select"
                multiple
                :disabled="disableSelect"
                :reduce="functions => functions.id"
                :options="functionData"
                :close-on-select="false"
                v-model="payload.functionId"
                @input="selectedFunctions"
              >
                <template #option="{ name, id }">
                  <div class="flex">
                    <div class="hover:text-white">
                      <div class="flex justify-start items-center gap-2">
                        <c-checkbox :true-value="!payload.functionId.includes(id)" @focus.prevent :key="id"  />
                        <span class="font-bold text-base cursor-pointer">{{ name }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <template #selected-option="{name}">
                  {{ name }}
                </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <Icon icon-name="caret-down" size="xsm" />
                  </span>
                </template>
              </v-select>
          </div>


          <div class="flex w-full flex-col p-1 mt-4">
              <label class="date-label">Job Level(s)</label>
              <v-select
                :clearable="false"
                class="style-select"
                multiple
                :disabled="disableSelect"
                :reduce="levels => levels.id"
                :options="levelData"
                :close-on-select="false"
                v-model="payload.levelId"
                @input="selectedLevels"
              >
                <template #option="{ name, id }">
                  <div class="flex">
                    <div class="hover:text-white">
                      <div class="flex justify-start items-center gap-2">
                        <c-checkbox :true-value="!payload.levelId.includes(id)" @focus.prevent :key="id"  />
                        <span class="font-bold text-base cursor-pointer">{{ name }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <template #selected-option="{name}">
                  {{ name }}
                </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <Icon icon-name="caret-down" size="xsm" />
                  </span>
                </template>
              </v-select>
          </div>


          <div class="flex w-full flex-col p-1 mt-4">
              <label class="date-label">Designation(s)</label>
              <v-select
                :clearable="false"
                class="style-select"
                multiple
                :disabled="disableSelect"
                :reduce="designation => designation.id"
                :options="rolesData"
                :close-on-select="false"
                v-model="payload.designationId"
                @input="selectedDesignations"
              >
                <template #option="{ name, id }">
                  <div class="flex">
                    <div class="hover:text-white">
                      <div class="flex justify-start items-center gap-2 my-1">
                        <c-checkbox :true-value="!payload.designationId.includes(id)" @focus.prevent :key="id"  />
                        <span class="font-bold text-base cursor-pointer">{{ name }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <template #selected-option="{name}">
                  {{ name }}
                </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <Icon icon-name="caret-down" size="xsm" />
                  </span>
                </template>
              </v-select>
          </div>


      </RightSideBar>
      </ValidationObserver>
   </template>

<script>
import { ValidationObserver } from "vee-validate";
import VSelect from "vue-select"
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
import CCheckbox from "@scelloo/cloudenly-ui/src/components/checkbox";
import "vue-select/dist/vue-select.css";

export default {
  name: "FilterPayrun",
  components: {
      ValidationObserver,
      VSelect,
    SearchInput,
    CCheckbox,
    RightSideBar: () => import("@/components/RightSideBar"),
   // CSelect: () => import("@scelloo/cloudenly-ui/src/components/select"),
   // CText: () => import("@scelloo/cloudenly-ui/src/components/text"),
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    },
    data() {
        return {
          handleSearch: '',
          designationParams: '',
          locationParams: '',
          functionParams: '',
          levelParams: '',
            locationData: [],
            allLocations: [],
            allFunctions: [],
            functionData: [],
            allRoles: [],
          rolesData: [],
          allLevels: [],
            levelData: [],
            payload: {
                locationId: [],
                designationId: [],
                functionId: [],
                levelId: []
            },
            disableSelect: false,
        disableButton: true,
    }
    },

    methods: {
      submit() {
        const searchKey = this.handleSearch === '' ? '' : `search=${this.handleSearch}`;
        const params = `${searchKey}${this.locationParams}${this.functionParams}${this.levelParams}${this.designationParams}`;

        this.$router.push({
          name: 'ViewThirteenthEmployees',
          query: { slug: `${params}` },
        })
      },

        closeModal() {
            this.handleSearch = "";
            this.disableSelect = false;
            this.$emit('close', true);
    },
    // get all locations
        getLocations() {
        this.$_getHumanarLocations().then(result => {
        this.allLocations = [...result.data.outlets];
        this.locationData = [...this.allLocations]
      });
    },
    // get all functions
    getFunctions() {
      this.$_getFunctionKpis().then(result => {
        this.allFunctions = [...result.data.functions];
        this.functionData = result.data.functions
        this.loadingData = false;
      });
    },
    getDesignations() {
      this.$_getDesignations("").then(response => {
        this.allRoles = [...response.data.designations];
        this.rolesData = response.data.designations;
        this.loadingData = false;
      });
      },

      getLevels() {
        this.$_getLevels().then((response) => {
        this.allLevels = [...response.data.levels];
        this.levelData = response.data.levels;
        this.loadingData = false;
      });
    },

     selectedDesignations(designationData) {
       this.payload.designationId = [];
       if (designationData.length < this.allRoles.length) {
        this.rolesData = this.allRoles;
        this.rolesData.forEach((data) => {
          if (designationData.includes(data.id)) {
            this.payload.designationId.push(data.id)
          }
        });

         if (designationData.length > 0) {
           this.designationParams = `&designationId=${this.payload.designationId.toString()}`;
         }
         else {
           this.designationParams = '';
         }

        this.disableButton = false;
      } else {
        this.rolesData = this.allRoles;
        this.disableButton = true;
      }
        },


     selectedLocations(locationData) {
       this.payload.locationId = [];
       if (locationData.length < this.allLocations.length) {
        this.locationData = this.allLocations;
        this.locationData.forEach((data) => {
          if (locationData.includes(data.id)) {
            this.payload.locationId.push(data.id)
          }
        });

         if (locationData.length > 0) {
           this.locationParams = `&locationId=${this.payload.locationId.toString()}`;
         }
         else {
           this.locationParams = '';
         }

        this.disableButton = false;
      } else {
        this.locationData = this.allLocations;
      }
      },


     selectedFunctions(functionData) {
       this.payload.functionId = [];
      if (functionData.length < this.allFunctions.length) {
        this.functionData = this.allFunctions;
        this.functionData.forEach((data) => {
          if (functionData.includes(data.id)) {
            this.payload.functionId.push(data.id)
          }
        });

         if (functionData.length > 0) {
           this.functionParams = `&functionId=${this.payload.functionId.toString()}`;
         }
         else {
           this.functionParams = '';
         }

        this.disableButton = false;
      } else {
        this.functionData = this.allFunctions;
      }
      },


     selectedLevels(levelData) {
       this.payload.levelId = [];
       if (levelData.length < this.allLevels.length) {
        this.levelData = this.allLevels;
        this.levelData.forEach((data) => {
          if (levelData.includes(data.id)) {
            this.payload.levelId.push(data.id)
          }
        });

         if (levelData.length > 0) {
           this.levelParams = `&levelId=${this.payload.levelId.toString()}`;
         }
         else {
           this.levelParams = '';
         }

        this.disableButton = false;
      } else {
        this.levelData = this.allLevels;
      }
      },

        searchFilter(value) {
            if (value !== "") {
              this.disableSelect = true;
              this.disableButton = false;
            }
            else {
                this.disableSelect = false;
              this.payload.designationId = [];
              this.disableButton = true;
            }
        }

    },

    mounted() {
        this.getLocations();
        this.getFunctions();
      this.getDesignations();
      this.getLevels();
}
}
</script>
